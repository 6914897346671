<template>
  <div>
    <NavigationBar
      showBackBtn
      titel="Abrechnungsstapel exportieren"
      isPreview
    ></NavigationBar>
    <section>
      <v-btn @click="exportWorkbook">Export</v-btn>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import ExcelJS from "exceljs";

export default {
  name: "accounting-payroll-export",
  components: {
    NavigationBar,
  },
  data() {
    return {
      batchData: {},
    };
  },
  computed: {},
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    async initialize() {
      await this.getBatch();
    },
    async getBatch() {
      try {
        const doc = await db
          .collection("organizations")
          .doc(this.$route.params.organizationId)
          .collection("payrollBatches")
          .doc(this.$route.params.itemId)
          .get();
        if (doc.exists) {
          this.batchData = doc.data();
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.log("Error getting document:", error);
        return false;
      }
    },
    convertHexToArgbColor(hexColor) {
      console.log(hexColor);
      return "FF00FF00"; //TODO
    },
    async exportWorkbook() {
      const workbook = new ExcelJS.Workbook();

      // Create worksheets for each payroll group
      this.batchData.payrollGroups.data.forEach((payrollGroup) => {
        const worksheet = workbook.addWorksheet(payrollGroup.title, {
          properties: {
            tabColor: { argb: this.convertHexToArgbColor(payrollGroup.color) },
          },
          pageSetup: { paperSize: 9, orientation: "landscape" },
        });

        // worksheet.columns = [
        //   {
        //     header: ["", "", "", "", "Lfd. Nr."],
        //     key: "sequenceNumber",
        //     width: 6,
        //     alignment: { horizontal: "center" },
        //     font: { bold: true },
        //   },
        //   {
        //     header: ["", "", "", "", "Nachname"],
        //     key: "lastName",
        //     width: 15,
        //     alignment: { vertical: "bottom", horizontal: "left" },
        //     font: { bold: true },
        //   },
        //   {
        //     header: ["", "", "", "", "Vorname"],
        //     key: "lastName",
        //     width: 15,
        //     alignment: { vertical: "bottom", horizontal: "left" },
        //     font: { bold: true },
        //   },
        //   {
        //     header: ["", "", "", "", "Vorname"],
        //     key: "lastName",
        //     width: 15,
        //     alignment: { vertical: "bottom", horizontal: "left" },
        //     font: { bold: true },
        //   },
        // ];

        // Add "Lfd. Nr." column in column A with bold font in only the header cell in the first row
        worksheet.getCell("A5").value = "Lfd. Nr.";
        worksheet.getColumn("A").width = 6;
        worksheet.getColumn("A").alignment = { horizontal: "center" };
        worksheet.getColumn("A").fill = {
          type: "pattern",
          pattern: "solid",
          // fgColor: { argb: "F08080" },
          fgColor: { argb: "fff2f2f2" },
        };
        worksheet.getCell("A5").font = { bold: true };

        // Add "Name" column in column B
        worksheet.getCell("B5").value = "Name";
        worksheet.getColumn("B").width = 20;
        worksheet.getCell("B5").alignment = {
          vertical: "bottom",
          horizontal: "left",
        };
        worksheet.getCell("B4").font = { bold: true };

        // Add "Vorname" column in column C
        worksheet.getCell("C5").value = "Vorname";
        worksheet.getColumn("C").width = 20;
        worksheet.getCell("C5").alignment = {
          vertical: "bottom",
          horizontal: "left",
        };
        worksheet.getCell("C5").font = { bold: true };

        // Add one row for each person
        // look at the sequence number of each person; the sequence number is the order in which the persons are displayed in the payroll group; if there are sequence numbers in between that are not used, the row should be empty but the sequence number should be displayed

        // Find the maximum sequence number
        const maxSequenceNumber = Math.max(
          ...this.batchData.persons.map((person) => person.user.sequenceNumber)
        );

        // Create an array to hold the rows
        const rows = Array.from({ length: maxSequenceNumber }, (_, i) => ({
          sequenceNumber: i + 1,
          lastName: "",
          firstName: "",
        }));

        // Fill the array with person data
        this.batchData.persons.forEach((person) => {
          const index = person.user.sequenceNumber - 1;
          rows[index] = {
            sequenceNumber: person.user.sequenceNumber,
            lastName: person.user.lastName,
            firstName: person.user.firstName,
          };
        });

        // Add rows to the worksheet
        rows.forEach((row) => {
          worksheet.addRow([row.sequenceNumber, row.lastName, row.firstName]);
        });
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Abrechnungsdaten.xlsx";
      link.click();
    },
  },
};
</script>
