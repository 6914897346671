<template>
  <div>
    <NavigationBar
      showBackBtn
      titel="Abrechnungsstapel erstellen"
      :actions="actions"
    ></NavigationBar>
    <error-message
      v-if="error.message"
      :message="error.message"
    ></error-message>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        @submit.prevent="createItem"
        ref="form"
      >
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-format-list-bulleted-type"
                title="Allgemein"
              ></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            depressed
                            class="mr-2"
                            @click="setPreviousMonth"
                            >Letzten Monat einsetzen</v-btn
                          >
                          <v-btn depressed @click="resetPeriod"
                            ><v-icon left>mdi-restore</v-icon
                            >Zurücksetzen</v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-row>
                            Ausschlaggebend ist der Startzeitpunkt von
                            Dienstberichten, Stundenmeldungen etc.
                            <v-col cols="12" sm="6">
                              <v-text-field
                                v-model="payrollGroup.period.start.date"
                                type="date"
                                label="Startdatum"
                                hint="DD.MM.YYYY"
                                outlined
                                :rules="[rules.required, rules.startBeforeEnd]"
                                required
                                prepend-inner-icon="mdi-calendar-start"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field
                                v-model="payrollGroup.period.start.time"
                                label="Startzeit"
                                hint="HH:MM"
                                :rules="[rules.required, rules.startBeforeEnd]"
                                outlined
                                type="time"
                                prepend-inner-icon="mdi-clock-start"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="6">
                              <v-text-field
                                v-model="payrollGroup.period.end.date"
                                type="date"
                                label="Enddatum"
                                hint="DD.MM.YYYY"
                                outlined
                                :rules="[rules.required, rules.startBeforeEnd]"
                                required
                                prepend-inner-icon="mdi-calendar-end"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field
                                v-model="payrollGroup.period.end.time"
                                label="Endzeit"
                                hint="HH:MM"
                                outlined
                                :rules="[rules.required, rules.startBeforeEnd]"
                                type="time"
                                prepend-inner-icon="mdi-clock-end"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model.trim="payrollGroup.title"
                            label="Bezeichnung"
                            required
                            hide-details="auto"
                            :rules="[rules.required]"
                            outlined
                          ></v-text-field>
                          <v-radio-group
                            v-model="payrollGroup.type"
                            label="Abrechnungsvariante"
                            mandatory
                          >
                            <v-radio
                              label="Regulärer Turnus"
                              value="regular"
                            ></v-radio>
                            <v-radio
                              label="Sonderabrechnung"
                              value="special"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="12"
                          ><v-select
                            v-model="payrollGroup.payrollGroups.ids"
                            :items="payrollGroups"
                            label="Abrechnungsgruppen auswählen (Filter)"
                            small-chips
                            deletable-chips
                            multiple
                            :rules="[rules.required]"
                            outlined
                            hide-details="auto"
                            item-text="title"
                            item-value="meta.id"
                          ></v-select
                        ></v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
            <v-col cols="12">
              <Subheader icon="mdi-table" title="Abrechnungsdaten"></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-data-table
                    v-model="helpers.payrollGroupTable.selection"
                    :headers="helpers.payrollGroupTable.headers"
                    :items="payrollGroup.persons"
                    multi-sort
                    group-by="user.division.title"
                    item-key="user.uid"
                    sort-by="user.displayName"
                    :sort-desc="false"
                    dense
                    show-select
                    :items-per-page="15"
                  >
                    <template v-slot:top>
                      <v-card flat>
                        <v-card-title>
                          <v-spacer class="hidden-sm-and-down"></v-spacer>
                          <v-btn
                            depressed
                            text
                            outlined
                            color="error"
                            class="mr-2"
                            @click="deleteAttendanceSelection"
                            ><v-icon left>mdi-delete</v-icon>Auswahl löschen
                          </v-btn>
                          <v-btn
                            color="success"
                            depressed
                            @click="toggleAddPersonDialog"
                            class="mr-2"
                          >
                            <v-icon left>mdi-account-plus</v-icon>Personen
                            hinzufügen
                          </v-btn>
                        </v-card-title></v-card
                      >
                    </template>
                    <template
                      v-slot:[`group.header`]="{ items, isOpen, toggle }"
                    >
                      <th
                        :colspan="helpers.payrollGroupTable.headers.length + 1"
                        class="group-header-th"
                      >
                        <v-icon @click="toggle" class="mr-2"
                          >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                        </v-icon>
                        <span>
                          {{ items[0].user.division.title }}
                        </span>
                      </th>
                    </template>
                  </v-data-table>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </section>
    <CustomDialog
      v-if="helpers.addPersonDialog.model"
      @close="toggleAddPersonDialog()"
      title="Personen auswählen"
      :loading="helpers.addPersonDialog.loading"
      :btnAction="addPersonsToPayroll"
      :errorMessage="helpers.addPersonDialog.errorMessage"
      btnText="Auswählen"
      width="m"
    >
      <template v-slot:content>
        <br />
        <v-card outlined>
          <v-data-table
            v-model="helpers.addPersonDialog.selection"
            :items="memberships"
            :headers="helpers.addPersonDialog.headers"
            item-key="user.uid"
            sort-by="user.displayName"
            :sort-desc="false"
            dense
            :search="helpers.addPersonDialog.search"
            show-select
            hide-default-footer
            disable-pagination
            checkbox-color="primary"
            group-by="user.division.title"
            mobile-breakpoint="0"
            fixed-header
            height="35vh"
            class="mt-1"
            no-results-text="Keine Personen für Suchbegriff"
          >
            <template v-slot:top>
              <v-card flat>
                <v-card-subtitle>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="helpers.addPersonDialog.search"
                        label="Nach Personen suchen"
                        prepend-inner-icon="mdi-magnify"
                        dense
                        hide-details="auto"
                        outlined
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-btn disabled depressed class="mt-4"
                      ><v-icon left>mdi-filter</v-icon>Filtern</v-btn
                    >

                    <!-- <v-col>
                        <span class="mr-4">Sortieren nach</span>
                        <v-chip-group
                          v-model="helpers.dataImport.sortSelector"
                          mandatory
                          active-class="primary--text"
                        >
                          <v-chip
                            label
                            value="sortKey"
                            class="transparent-background font-weight-medium mr-2 mb-3"
                            ><v-icon left small>mdi-sort-numeric-variant</v-icon
                            >Sortierschlüssel
                          </v-chip>
                          <v-chip
                            label
                            value="title"
                            class="transparent-background font-weight-medium mb-3"
                            ><v-icon left small
                              >mdi-sort-alphabetical-variant</v-icon
                            >Alphabetisch</v-chip
                          >
                        </v-chip-group>
                      </v-col> -->
                  </v-row>
                </v-card-subtitle>
              </v-card>
              <v-divider></v-divider>
            </template>
            <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
              <th
                :colspan="helpers.addPersonDialog.headers.length + 1"
                class="group-header-th"
              >
                <v-icon @click="toggle" class="mr-2"
                  >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>
                <span>
                  {{ items[0].user.division.title }}
                </span>
              </th>
            </template></v-data-table
          >
        </v-card>
      </template>
    </CustomDialog>
    <CustomDialog
      v-if="helpers.actionDialog.model"
      @close="toggleActionDialog()"
      title="Abrechnungsstapel erstellen"
      text="Das Erstellen des Abrechnungsstapels und Generieren der Abrechnungsdaten kann einige Minuten dauern. Bitte warte, bis der Vorgang abgeschlossen ist."
      :loading="helpers.actionDialog.loading"
      :btnAction="createItem"
      :errorMessage="helpers.actionDialog.errorMessage"
      :btnDisabled="!helpers.formIsValid"
      btnText="Verarbeitung starten"
      width="s"
    ></CustomDialog>
  </div>
</template>

<script>
import { Timestamp, functions } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import ErrorMessage from "@/components/_systemwide/ErrorMessage.vue";
// import PayrollEditor from "@/components/accounting/payroll/PayrollEditor.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import { ACCOUNTING_PAYROLL_create } from "@/data/permission-types.js";
import { ADMIN, ACCOUNTING } from "@/store/modules.js";
import { GET_USER_LIST, GET_PAYROLL_GROUPS } from "@/store/action-types.js";

export default {
  name: "accounting-payroll-new",
  components: {
    NavigationBar,
    // PayrollEditor,
    Subheader,
    ErrorMessage,
    CustomDialog,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          permission: `${ACCOUNTING_PAYROLL_create}`,
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.toggleActionDialog,
        },
      ],

      helpers: {
        formIsValid: false,
        addPersonDialog: {
          model: false,
          loading: false,
          search: "",
          errorMessage: "",
          headers: [{ text: "Name", value: "user.displayName" }],
          selection: [],
        },
        actionDialog: {
          model: false,
          loading: false,
          errorMessage: "",
        },
        payrollGroupTable: {
          headers: [
            { text: "Name", value: "user.displayName" },
            { text: "", value: "data-table-expand", sortable: false },
            { value: "action", sortable: false, width: "1%" },
          ],
          selection: [],
          expandedRows: [],
        },
      },
      error: {
        message: "",
      },
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        startBeforeEnd: (value) => !!value || "Feld ist erforderlich.",
        //   (!this.fieldsAreFilled &&
        //     this.convertToTimestamp(
        //       this.payrollLocal.period.start.date,
        //       this.payrollLocal.period.start.time
        //     ).valueOf() <
        //       this.convertToTimestamp(
        //         this.payrollLocal.period.end.date,
        //         this.payrollLocal.period.end.time
        //       ).valueOf()) ||
        // "Start muss vor Ende liegen",
      },

      payrollGroup: {
        title: "",
        type: "regular",
        period: {
          start: { date: null, time: null, timestamp: null },
          end: { date: null, time: null, timestamp: null },
        },
        persons: [],
        payrollGroups: {
          data: [],
          ids: [],
        },
      },
    };
  },
  computed: {
    memberships() {
      return this.$store.state.admin.membershipsPublic.map((membership) => {
        return {
          user: membership.user,
        };
      });
    },
    payrollGroups() {
      return this.$store.state.accounting.payrollGroups.map((payrollGroup) => {
        return {
          ...payrollGroup,
          // borderColorStyle: this.createBorderColorStyle(payrollGroup.color),
        };
      });
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "active",
        },
      });
      this.$store.dispatch(`${ACCOUNTING}${GET_PAYROLL_GROUPS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    setPreviousMonth() {
      const date = new Date();
      date.setDate(1);
      date.setHours(-1);
      const lastDay = new Date(date);
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
      this.payrollGroup.period.start.date = firstDay
        .toISOString()
        .split("T")[0];
      this.payrollGroup.period.start.time = "00:00";
      this.payrollGroup.period.end.date = lastDay.toISOString().split("T")[0];
      this.payrollGroup.period.end.time = "23:59";
    },
    resetPeriod() {
      this.payrollGroup.period.start.date = null;
      this.payrollGroup.period.start.time = null;
      this.payrollGroup.period.end.date = null;
      this.payrollGroup.period.end.time = null;
    },
    deleteAttendanceSelection() {},
    toggleAddPersonDialog() {
      this.helpers.addPersonDialog.model = !this.helpers.addPersonDialog.model;
    },
    addPersonsToPayroll() {
      this.payrollGroup.persons = this.helpers.addPersonDialog.selection;
      this.toggleAddPersonDialog();
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
    toggleActionDialog() {
      if (this.helpers.actionDialog.model) {
        this.helpers.actionDialog.model = !this.helpers.actionDialog.model;
      } else {
        this.$refs.form.validate();
        if (this.helpers.formIsValid) {
          this.helpers.actionDialog.model = !this.helpers.actionDialog.model;
        } else {
          alert("Prüfen Deine Eingabe und versuchen es erneut.");
        }
      }
    },
    async createItem() {
      const isValid = await this.$refs.form.validate();
      if (isValid) {
        this.helpers.formIsValid = true;
        this.helpers.actionDialog.errorMessage = "";
        this.helpers.actionDialog.loading = true;

        const callFunction = functions.httpsCallable(
          "accounting-createPayrollBatch"
        );
        try {
          const result = await callFunction({
            organizationId: this.$route.params.organizationId,
            payrollBatch: {
              title: this.payrollGroup.title,
              type: this.payrollGroup.type,
              period: {
                start: {
                  timestamp: this.convertToTimestamp(
                    this.payrollGroup.period.start.date,
                    this.payrollGroup.period.start.time
                  ),
                },
                end: {
                  timestamp: this.convertToTimestamp(
                    this.payrollGroup.period.end.date,
                    this.payrollGroup.period.end.time
                  ),
                },
              },
              persons: this.payrollGroup.persons,
              payrollGroups: { ids: this.payrollGroup.payrollGroups.ids },
            },
          });
          const payrollBatchId = result.data;
          this.$router.push({
            name: "accounting-payroll-details",
            params: {
              organizationId: this.$route.params.organizationId,
              itemId: payrollBatchId,
            },
          });
        } catch (error) {
          this.helpers.actionDialog.errorMessage =
            error.message ||
            "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.";
          this.helpers.actionDialog.loading = false;
          console.error("Error creating payroll type: ", error);
        }
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
    // createItem() {
    //   this.$refs.form.validate();
    //   if (this.helpers.formIsValid) {
    //     if (
    //       this.payroll.user.uid !== auth.currentUser.uid &&
    //       !this.permissionToManageLeaves
    //     ) {
    //       alert(
    //         "Sie dürfen keine Abmeldungen für andere Mitglieder erstellen."
    //       );
    //     } else if (
    //       this.convertToTimestamp(
    //         this.payroll.period.start.date,
    //         this.payroll.period.start.time
    //       ).valueOf() >
    //       this.convertToTimestamp(
    //         this.payroll.period.end.date,
    //         this.payroll.period.end.time
    //       ).valueOf()
    //     ) {
    //       alert("Fehler: Das Startdatum muss vor dem Enddatum liegen.");
    //     } else {
    //       this.$store
    //         .dispatch(`${ACCOUNTING}${CREATE_PAYROLL}`, {
    //           organizationId: this.$route.params.organizationId,

    //           // DATA
    //         })
    //         .then(function () {
    //           this.$router.push({
    //             name: "accounting-payroll",
    //             params: {
    //               organizationId: this.$route.params.organizationId,
    //             },
    //           });
    //         })
    //         .catch(function (error) {
    //           alert(
    //             "Es ist ein Fehler aufgetreten. \n\nKontaktiere den Support mit folgender Meldung\n" +
    //               error
    //           );
    //           console.error(error);
    //         });
    //     }
    //   } else {
    //     alert("Prüfe Deine Eingabe und versuche es erneut.");
    //   }
    // },
  },
};
</script>
